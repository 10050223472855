////////////////////////////////////////////////
/// Los Getters son acciones realizadas en los estados para obtener informacion
/// acerca de ellos
////////////////////////////////////////////////

const getters = {
    layout(state) {
        return state.layout;
    },
    is_logged(state) {
        return state.is_logged;
    },
    user(state) {
        return state.user;
    },
    reloadPage(state) {
        return state.reloadPage;
    },
};

export default getters;
