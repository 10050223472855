<template>
  <div class="layout-px-spacing dash_1">
    <teleport to="#breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:;">Gerencia</a></li>
                <li class="breadcrumb-item active" aria-current="page"><span>Dashboard</span></li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
      <!-- <ul class="navbar-nav flex-row ms-auto">
        <li class="nav-item more-dropdown">
          <img src="@/images/popsy.png" alt="popsy" />
        </li>
      </ul> -->
    </teleport>
    <div class="row layout-top-spacing">
      <p class="mb-4" style="font-size: 19px">
        Buen día, <span class="text-success">{{ user.nombre }}</span>
      </p>
      <!-- Vehiculos Disponibles -->
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Vehículos Disponibles</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options1" height="450" type="donut" :options="sales_donut_options1" :series="vehiculos"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Propietarios Disponibles -->
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Propietarios Disponibles</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options1" height="450" type="donut" :options="sales_donut_options1" :series="propietarios"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Conductores Disponibles -->
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Conductores Disponibles</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options1" height="450" type="donut" :options="sales_donut_options1" :series="conductores"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Auxiliares Disponibles -->
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Auxiliares Disponibles</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="sales_donut_options1" height="450" type="donut" :options="sales_donut_options1" :series="auxiliares"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Informe Remesas y Manifiestos -->
      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-revenue">
          <div class="widget-heading">
            <h5>Informe Anual de Remesas y Manifiestos</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="revenue_options" height="325" type="area" :options="revenue_options" :series="data_rem_man"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Consolidado Remesas y Manifiestos -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-wallet-balance">
          <div class="widget-heading d-block">
            <div class="wallet-usr-info">
              <div class="usr-name">
                <span> Consolidado Anual</span>
              </div>
            </div>
            <div class="wallet-balance">
              <p>Remesas & Manifiestos</p>
            </div>
          </div>
          <div class="widget-amount">
            <div class="w-a-info funds-received me-3">
              <span>
                Remesas
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-up text-success float-end"
                >
                  <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
              </span>
              <p>{{ total_remesas }}</p>
            </div>
            <div class="w-a-info funds-spent">
              <span>
                Manifiestos
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-down text-success float-end"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </span>
              <p>{{ total_manifiestos }}</p>
            </div>
          </div>
          <div class="widget-content">
            <!-- <div class="bills-stats mb-4">
              <span>Pending</span>
            </div> -->
            <div class="invoice-list mt-3">
              <div class="inv-detail mb-4">
                <div class="info-detail-1">
                  <p class="text-danger">Remesas Urbanas</p>
                  <p>{{ tipos?.remesas_urbanas || 0 }}</p>
                </div>
                <div class="info-detail-2">
                  <p class="text-danger">Remesas Nacionales</p>
                  <p>{{ tipos?.remesas_nacionales || 0 }}</p>
                </div>
                <div class="info-detail-1">
                  <p class="text-info">Manifiestos Urbanos</p>
                  <p>{{ tipos?.manifiestos_urbanos || 0 }}</p>
                </div>
                <div class="info-detail-2">
                  <p class="text-info">Manifiestos Nacionales</p>
                  <p>{{ tipos?.manifiestos_nacionales || 0 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Consolidado Remesas -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        <div class="widget widget-summary">
          <div class="widget-heading">
            <h5>Remesas</h5>
          </div>
          <div class="widget-content">
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Activas</h6>
                  <p class="summary-count">{{ tipos?.remesas_activas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="8"
                      class="progress-bar bg-gradient-primary"
                      :style="{ width: (tipos.total_remesas > 0 ? (tipos.remesas_activas / tipos.total_remesas) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Asignadas</h6>
                  <p class="summary-count">{{ tipos?.remesas_asignadas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="32"
                      class="progress-bar bg-gradient-warning"
                      :style="{ width: (tipos.total_remesas > 0 ? (tipos.remesas_asignadas / tipos.total_remesas) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Cumplidas</h6>
                  <p class="summary-count">{{ tipos?.remesas_cumplidas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="68"
                      class="progress-bar bg-gradient-success"
                      :style="{ width: (tipos.total_remesas > 0 ? (tipos.remesas_cumplidas / tipos.total_remesas) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Facturadas</h6>
                  <p class="summary-count">{{ tipos?.remesas_facturadas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="0"
                      class="progress-bar bg-gradient-secondary"
                      :style="{ width: (tipos.total_remesas > 0 ? (tipos.remesas_facturadas / tipos.total_remesas) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Anuladas</h6>
                  <p class="summary-count">{{ tipos?.remesas_anuladas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="2"
                      class="progress-bar bg-gradient-danger"
                      :style="{ width: (tipos.total_remesas > 0 ? (tipos.remesas_anuladas / tipos.total_remesas) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Consolidado Manifiestos -->
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
        <div class="widget widget-summary">
          <div class="widget-heading">
            <h5>Manifiestos</h5>
          </div>
          <div class="widget-content">
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Activas</h6>
                  <p class="summary-count">{{ tipos?.manifiestos_activas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="0"
                      class="progress-bar bg-gradient-primary"
                      :style="{ width: (tipos.total_manifiestos > 0 ? (tipos.manifiestos_activas / tipos.total_manifiestos) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Asignados</h6>
                  <p class="summary-count">{{ tipos?.manifiestos_asignadas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="65"
                      class="progress-bar bg-gradient-warning"
                      :style="{ width: (tipos.total_manifiestos > 0 ? (tipos.manifiestos_asignadas / tipos.total_manifiestos) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Cumplidos</h6>
                  <p class="summary-count">{{ tipos?.manifiestos_cumplidas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="80"
                      class="progress-bar bg-gradient-success"
                      :style="{ width: (tipos.total_manifiestos > 0 ? (tipos.manifiestos_cumplidas / tipos.total_manifiestos) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Facturados</h6>
                  <p class="summary-count">{{ tipos?.manifiestos_facturadas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="0"
                      class="progress-bar bg-gradient-secondary"
                      :style="{ width: (tipos.total_manifiestos > 0 ? (tipos.manifiestos_facturadas / tipos.total_manifiestos) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="summary-list">
              <div class="w-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
              </div>
              <div class="w-summary-details">
                <div class="w-summary-info">
                  <h6>Anulados</h6>
                  <p class="summary-count">{{ tipos?.manifiestos_anuladas || 0 }}</p>
                </div>
                <div class="w-summary-stats">
                  <div class="progress">
                    <div
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      aria-valuenow="80"
                      class="progress-bar bg-gradient-danger"
                      :style="{ width: (tipos.total_manifiestos > 0 ? (tipos.manifiestos_anuladas / tipos.total_manifiestos) * 100 : 0) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Progreso de Visitas -->
      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Progreso de Visitas</h5>
          </div>
          <div class="widget-content">
            <apex-chart height="325" type="radialBar" :options="options_8" :series="series_8"></apex-chart>
          </div>
        </div>
      </div>
      <!-- Entregas Diarias -->
      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-unique-visitors">
          <div class="widget-heading">
            <h5>Entregas Diarias</h5>
          </div>
          <div class="widget-content">
            <apex-chart v-if="unique_visitor_options" height="350" type="bar" :options="unique_visitor_options" :series="unique_visitor_series"></apex-chart>
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-unique-visitors">
                    <div class="widget-heading">
                        <h5>Periodos de Entregas</h5>
                        <div class="task-action">
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlVisitors" class="btn dropdown-toggle btn-icon-only" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlVisitors">
                                    <li><a href="javascript:;" class="dropdown-item">View</a></li>
                                    <li><a href="javascript:;" class="dropdown-item">Update</a></li>
                                    <li><a href="javascript:;" class="dropdown-item">Download</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content">
                        <apex-chart v-if="unique_visitor_options" height="350" type="bar" :options="unique_visitor_options" :series="unique_visitor_series"></apex-chart>
                    </div>
                </div>
            </div> -->

      <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
                <div class="widget widget-dailysales">
                    <div class="widget-heading">
                        <div>
                            <h5>Daily sales</h5>
                            <span class="sub-title">Go to columns for details.</span>
                        </div>
                        <div class="w-icon text-warning">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-dollar-sign"
                            >
                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="widget-content">
                        <apex-chart v-if="daily_sales_options" height="160" type="bar" :options="daily_sales_options" :series="daily_sales_series"></apex-chart>
                    </div>
                </div>
            </div>-->

      <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-total-order">
                    <div class="widget-heading">
                        <div class="w-icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-shopping-cart"
                            >
                                <circle cx="9" cy="21" r="1"></circle>
                                <circle cx="20" cy="21" r="1"></circle>
                                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                            </svg>
                        </div>
                        <div>
                            <div class="w-value">3,192</div>
                            <div class="w-numeric-title">Total Orders</div>
                        </div>
                    </div>
                    <div class="widget-content p-0">
                        <apex-chart v-if="total_orders_options" height="290" type="area" :options="total_orders_options" :series="total_orders_series"></apex-chart>
                    </div>
                </div>
            </div> -->

      <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-activity">
                    <div class="widget-heading">
                        <h5>Recent Activities</h5>
                    </div>
                    <div class="widget-content">
                        <perfect-scrollbar class="timeline-line">
                            <div class="item-timeline timeline-primary">
                                <div class="badge badge-primary"></div>
                                <div class="t-text">
                                    <p><span>Updated</span> Server Logs</p>
                                    <span class="badge badge-outline-primary outline-badge-primary icon-fill-primary">Pending</span>
                                    <p class="t-time">Just Now</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Send Mail to <a href="javascript:void(0);">HR</a> and <a href="javascript:void(0);">Admin</a></p>
                                    <span class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">2 min ago</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-danger">
                                <div class="badge badge-danger"></div>
                                <div class="t-text">
                                    <p>Backup <span>Files EOD</span></p>
                                    <span class="badge badge-outline-danger outline-badge-danger icon-fill-danger">Pending</span>
                                    <p class="t-time">14:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Collect documents from <a href="javascript:void(0);">Sara</a></p>
                                    <span class="badge badge-outline-dark outline-badge-dark icon-fill-dark">Completed</span>
                                    <p class="t-time">16:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Conference call with <a href="javascript:void(0);">Marketing Manager</a>.</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">In progress</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-secondary">
                                <div class="badge badge-secondary"></div>
                                <div class="t-text">
                                    <p>Rebooted Server</p>
                                    <span class="badge badge-outline-secondary outline-badge-secondary icon-fill-secondary">Completed</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Send contract details to Freelancer</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">Pending</span>
                                    <p class="t-time">18:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Kelly want to increase the time of the project.</p>
                                    <span class="badge badge-outline-dark outline-badge-dark icon-fill-dark">In Progress</span>
                                    <p class="t-time">19:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Server down for maintanence</p>
                                    <span class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">19:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-secondary">
                                <div class="badge badge-secondary"></div>
                                <div class="t-text">
                                    <p>Malicious link detected</p>
                                    <span class="badge badge-outline-secondary outline-badge-secondary icon-fill-secondary">Block</span>
                                    <p class="t-time">20:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Rebooted Server</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">Completed</span>
                                    <p class="t-time">23:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-primary">
                                <div class="badge badge-primary"></div>
                                <div class="t-text">
                                    <p><span>Updated</span> Server Logs</p>
                                    <span class="badge badge-outline-primary outline-badge-primary icon-fill-primary">Pending</span>
                                    <p class="t-time">Just Now</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-success">
                                <div class="badge badge-success"></div>
                                <div class="t-text">
                                    <p>Send Mail to <a href="javascript:void(0);">HR</a> and <a href="javascript:void(0);">Admin</a></p>
                                    <span class="badge badge-outline-success outline-badge-success icon-fill-success">Completed</span>
                                    <p class="t-time">2 min ago</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-danger">
                                <div class="badge badge-danger"></div>
                                <div class="t-text">
                                    <p>Backup <span>Files EOD</span></p>
                                    <span class="badge badge-outline-danger outline-badge-danger icon-fill-danger">Pending</span>
                                    <p class="t-time">14:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-dark">
                                <div class="badge badge-dark"></div>
                                <div class="t-text">
                                    <p>Collect documents from <a href="javascript:void(0);">Sara</a></p>
                                    <span class="badge badge-outline-dark outline-badge-dark icon-fill-dark">Completed</span>
                                    <p class="t-time">16:00</p>
                                </div>
                            </div>

                            <div class="item-timeline timeline-warning">
                                <div class="badge badge-warning"></div>
                                <div class="t-text">
                                    <p>Conference call with <a href="javascript:void(0);">Marketing Manager</a>.</p>
                                    <span class="badge badge-outline-warning outline-badge-warning icon-fill-warning">In progress</span>
                                    <p class="t-time">17:00</p>
                                </div>
                            </div>
                        </perfect-scrollbar>

                        <div class="tm-action-btn">
                            <button type="button" class="btn btn-default">
                                <span>View All</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-arrow-right"
                                >
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-transaction">
                    <div class="widget-heading">
                        <h5>Transactions</h5>
                        <div class="task-action">
                            <div class="dropdown btn-group">
                                <a href="javascript:;" id="ddlTransactions" class="btn dropdown-toggle btn-icon-only" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-more-horizontal"
                                    >
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="ddlTransactions">
                                    <li><a href="javascript:;" class="dropdown-item">View Report</a></li>
                                    <li><a href="javascript:;" class="dropdown-item">Edit Report</a></li>
                                    <li><a href="javascript:;" class="dropdown-item">Mark as Done</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-info me-2 badge-info rounded-circle">
                                    <span class="b-avatar-text"><span>SP</span></span>
                                </span>
                                <div class="t-name">
                                    <h4>Shaun Park</h4>
                                    <p>10 Jan 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-success">+$36.11</div>
                        </div>

                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-warning me-2 badge-warning rounded-circle">
                                    <span class="b-avatar-custom"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="19"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-home"
                                        >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                        </svg>
                                    </span>
                                </span>
                                <div class="t-name">
                                    <h4>Electricity Bill</h4>
                                    <p>04 Jan 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-danger">-$16.44</div>
                        </div>

                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-danger me-2 badge-danger rounded-circle"
                                    ><span class="b-avatar-text"><span>AD</span></span></span
                                >
                                <div class="t-name">
                                    <h4>Amy Diaz</h4>
                                    <p>31 Jan 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-success">+$66.44</div>
                        </div>

                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-secondary me-2 badge-secondary rounded-circle"
                                    ><span class="b-avatar-custom"
                                        ><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="19"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="feather feather-home"
                                        >
                                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                            <polyline points="9 22 9 12 15 12 15 22"></polyline></svg></span
                                ></span>
                                <div class="t-name">
                                    <h4>Netflix</h4>
                                    <p>02 Feb 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-danger">-$32.00</div>
                        </div>

                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-info me-2 badge-info rounded-circle"
                                    ><span class="b-avatar-text"><span>DA</span></span></span
                                >
                                <div class="t-name">
                                    <h4>Daisy Anderson</h4>
                                    <p>15 Feb 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-success">+$10.08</div>
                        </div>

                        <div class="transactions-list">
                            <div>
                                <span class="b-avatar icon-fill-danger me-2 badge-danger rounded-circle"
                                    ><span class="b-avatar-text"><span>OG</span></span></span
                                >
                                <div class="t-name">
                                    <h4>Oscar Garner</h4>
                                    <p>20 Feb 1:00PM</p>
                                </div>
                            </div>
                            <div class="text-danger">-$22.00</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-wallet-balance">
                    <div class="widget-heading d-block">
                        <div class="wallet-usr-info">
                            <div class="usr-name">
                                <span><img src="@/assets/images/profile-32.jpeg" alt="admin-profile" class="img-fluid" /> Alan Green</span>
                            </div>
                            <div class="add">
                                <span
                                    ><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-plus"
                                    >
                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                        <line x1="5" y1="12" x2="19" y2="12"></line></svg
                                ></span>
                            </div>
                        </div>
                        <div class="wallet-balance">
                            <p>Wallet Balance</p>
                            <h5><span class="w-currency">$</span>2953</h5>
                        </div>
                    </div>

                    <div class="widget-amount">
                        <div class="w-a-info funds-received me-3">
                            <span
                                >Received
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-chevron-up text-success float-end"
                                >
                                    <polyline points="18 15 12 9 6 15"></polyline></svg
                            ></span>
                            <p>$97.99</p>
                        </div>
                        <div class="w-a-info funds-spent">
                            <span
                                >Spent
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-chevron-down text-danger float-end"
                                >
                                    <polyline points="6 9 12 15 18 9"></polyline></svg
                            ></span>
                            <p>$53.00</p>
                        </div>
                    </div>

                    <div class="widget-content">
                        <div class="bills-stats mb-4">
                            <span>Pending</span>
                        </div>
                        <div class="invoice-list">
                            <div class="inv-detail mb-4">
                                <div class="info-detail-1">
                                    <p>Netflix</p>
                                    <p><span class="w-currency">$</span> <span class="bill-amount">13.85</span></p>
                                </div>
                                <div class="info-detail-2">
                                    <p>BlueHost VPN</p>
                                    <p><span class="w-currency">$</span> <span class="bill-amount">15.66</span></p>
                                </div>
                            </div>
                            <div class="inv-action">
                                <a href="javascript:;" class="btn view-details btn-outline-secondary">View Details</a>
                                <a href="javascript:;" class="btn pay-now btn-outline-success">Pay Now $29.51</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-recent-orders">
                    <div class="widget-heading">
                        <h5>Recent Orders</h5>
                    </div>
                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><div class="th-content">Customer</div></th>
                                    <th><div class="th-content">Product</div></th>
                                    <th><div class="th-content">Invoice</div></th>
                                    <th><div class="th-content th-heading">Price</div></th>
                                    <th><div class="th-content">Status</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-13.jpeg" alt="avatar" /><span>Luke Ivory</span></div>
                                    </td>
                                    <td><div class="td-content text-primary">Headphone</div></td>
                                    <td><div class="td-content">#46894</div></td>
                                    <td>
                                        <div class="td-content"><span>$56.07</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-7.jpeg" alt="avatar" /><span>Andy King</span></div>
                                    </td>
                                    <td><div class="td-content text-warning">Nike Sport</div></td>
                                    <td><div class="td-content">#76894</div></td>
                                    <td>
                                        <div class="td-content"><span>$88.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-primary">Shipped</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-10.jpeg" alt="avatar" /><span>Laurie Fox</span></div>
                                    </td>
                                    <td><div class="td-content text-danger">Sunglasses</div></td>
                                    <td><div class="td-content">#66894</div></td>
                                    <td>
                                        <div class="td-content"><span>$126.04</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-5.jpeg" alt="avatar" /><span>Ryan Collins</span></div>
                                    </td>
                                    <td><div class="td-content text-warning">Sport</div></td>
                                    <td><div class="td-content">#89891</div></td>
                                    <td>
                                        <div class="td-content"><span>$108.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-primary">Shipped</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-4.jpeg" alt="avatar" /><span>Irene Collins</span></div>
                                    </td>
                                    <td><div class="td-content text-primary">Speakers</div></td>
                                    <td><div class="td-content">#75844</div></td>
                                    <td>
                                        <div class="td-content"><span>$84.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-danger">Pending</span></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content"><img src="@/assets/images/profile-11.jpeg" alt="avatar" /><span>Sonia Shaw</span></div>
                                    </td>
                                    <td><div class="td-content text-danger">Watch</div></td>
                                    <td><div class="td-content">#76844</div></td>
                                    <td>
                                        <div class="td-content"><span>$110.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="badge badge-success">Paid</span></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-top-selling-products">
                    <div class="widget-heading">
                        <h5>Top Selling Product</h5>
                    </div>

                    <div class="widget-content table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><div class="th-content">Product</div></th>
                                    <th><div class="th-content th-heading">Price</div></th>
                                    <th><div class="th-content th-heading">Discount</div></th>
                                    <th><div class="th-content">Sold</div></th>
                                    <th><div class="th-content">Source</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-headphones.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Headphone</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$168.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$60.09</span></div>
                                    </td>
                                    <td><div class="td-content">170</div></td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-danger"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-chevrons-right"
                                                >
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Direct</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-shoes.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Shoes</p>
                                                <p class="prd-category text-warning">Faishon</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$108.09</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$47.09</span></div>
                                    </td>
                                    <td><div class="td-content">130</div></td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-primary"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-chevrons-right"
                                                >
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Google</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-watch.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Watch</p>
                                                <p class="prd-category text-danger">Accessories</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$88.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$20.00</span></div>
                                    </td>
                                    <td><div class="td-content">66</div></td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-warning"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-chevrons-right"
                                                >
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Ads</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-laptop.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Laptop</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$110.00</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$33.00</span></div>
                                    </td>
                                    <td><div class="td-content">35</div></td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-info"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-chevrons-right"
                                                >
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Email</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="td-content d-flex">
                                            <img src="@/assets/images/product-camera.jpg" alt="product" />
                                            <div class="align-self-center">
                                                <p class="prd-name">Camera</p>
                                                <p class="prd-category text-primary">Digital</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="pricing">$126.04</span></div>
                                    </td>
                                    <td>
                                        <div class="td-content"><span class="discount-pricing">$26.04</span></div>
                                    </td>
                                    <td><div class="td-content">30</div></td>
                                    <td>
                                        <div class="td-content">
                                            <a href="javascript:void(0);" class="text-secondary"
                                                ><svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-chevrons-right"
                                                >
                                                    <polyline points="13 17 18 12 13 7"></polyline>
                                                    <polyline points="6 17 11 12 6 7"></polyline>
                                                </svg>
                                                Referral</a
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <logOut></logOut>
</template>

<script setup>
  import { onMounted, computed, ref } from 'vue';
  import logOut from '@/views/logOut/logOut.vue';
  import '@/assets/sass/widgets/widgets.scss';
  import '@/assets/sass/components/cards/card.scss';
  import { useStore } from 'vuex';
  import ApexChart from 'vue3-apexcharts';
  import { useMeta } from '@/composables/use-meta';
  useMeta({ title: 'Dashboard' });

  const store = useStore();
  const visitas = ref([]);
  const vehiculos = ref([]);
  const propietarios = ref([]);
  const conductores = ref([]);
  const auxiliares = ref([]);
  const tipos = ref([]);
  const data_rem_man = ref([]);
  const total_remesas = ref(0);
  const total_manifiestos = ref(0);
  const user = store.getters.user;

  onMounted(() => {
    getCountVehiculos();
    getPropietariosReady();
    getConductoresReady();
    getAuxiliaresReady();
    getDataRemesasManifiestos();
    getTotalTipoRemesasManifiestos();
  });

  async function getCountVehiculos() {
    const res = await Api.getCountVehiculos();
    if (res.status === 200) {
      const { data } = res;
      vehiculos.value = data.vehiculos.slice(0, 2).map(Number);
      //console.log(vehiculos.value);
    }
  }

  async function getConductoresReady() {
    const res = await Api.getConductoresReady();
    if (res.status === 200) {
      const { data } = res;
      // conductores.value = data.conductores.map(Number);
      conductores.value = data.conductores.slice(0, 2).map(Number);
      //console.log('conduct', conductores.value);
    }
  }

  async function getPropietariosReady() {
    const res = await Api.getPropietariosReady();
    if (res.status === 200) {
      const { data } = res;
      propietarios.value = data.propietarios.slice(0, 2).map(Number);
      //console.log(vehiculos.value);
    }
  }

  async function getAuxiliaresReady() {
    const res = await Api.getAuxiliaresReady();
    if (res.status === 200) {
      const { data } = res;
      auxiliares.value = data.auxiliares.slice(0, 2).map(Number);
      //console.log(vehiculos.value);
    }
  }

  async function getTotalTipoRemesasManifiestos() {
    const res = await Api.getTotalTipoRemesasManifiestos();
    if (res.status === 200) {
      const { data } = res;
      tipos.value = data;
      //console.log('llega en data', tipos.value);
    }
  }

  async function getDataRemesasManifiestos() {
    const res = await Api.getDataRemesasManifiestos();
    if (res.status === 200) {
      const { data } = res;
      data_rem_man.value = data.revenue_series;
      //console.log(data_rem_man.value);
      // Función para calcular la suma total del año
      const calculateTotalSum = (seriesData) => {
        return seriesData.reduce((acc, value) => acc + value, 0);
      };

      // Extraer los datos de remesas y manifiestos
      const remesasSeries = data_rem_man.value.find((series) => series.name === 'Remesas');
      const manifiestosSeries = data_rem_man.value.find((series) => series.name === 'Manifiestos');

      // Calcular las sumas anuales
      total_remesas.value = calculateTotalSum(remesasSeries.data);
      total_manifiestos.value = calculateTotalSum(manifiestosSeries.data);

      // console.log('Total Remesas:', total_remesas.value);
      // console.log('Total Manifiestos:', total_manifiestos.value);

      // const currentMonth = new Date().getMonth(); // 0 = Enero, 11 = Diciembre
      // const startMonth = 6; // Julio es el mes 6 (0 basado)

      // // Función para calcular la suma desde startMonth hasta currentMonth
      // const calculateSum = (seriesData, startMonth, endMonth) => {
      //   const filteredData = seriesData.slice(startMonth, endMonth + 1);
      //   return filteredData.reduce((acc, value) => acc + value, 0);
      // };

      // // Extraer los datos de remesas y manifiestos
      // const remesasSeries = data_rem_man.value.find((series) => series.name === 'Remesas');
      // const manifiestosSeries = data_rem_man.value.find((series) => series.name === 'Manifiestos');

      // // Calcular las sumas
      // total_remesas.value = calculateSum(remesasSeries.data, startMonth, currentMonth);
      // total_manifiestos.value = calculateSum(manifiestosSeries.data, startMonth, currentMonth);
    }
  }

  //Revenue
  // const revenue_series = ref([
  //   { name: 'Manifiestos', data: [15, 16, 14, 18, 13, 19, 25, 18, 32, 23, 12, 43] },
  //   { name: 'Remesas', data: [16, 13, 17, 18, 15, 16, 25, 19, 20, 32, 17, 44] },
  // ]);
  const currentMonth = new Date().getMonth();
  const revenue_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    return {
      chart: {
        fontFamily: 'Nunito, sans-serif',
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      dataLabels: { enabled: false },
      stroke: { show: true, curve: 'smooth', width: 2, lineCap: 'square' },
      dropShadow: { enabled: true, opacity: 0.2, blur: 10, left: -7, top: 22 },
      colors: is_dark ? ['#2196f3', '#e7515a'] : ['#1b55e2', '#e7515a'],
      markers: {
        discrete: [
          { seriesIndex: 0, dataPointIndex: currentMonth, fillColor: '#1b55e2', strokeColor: '#fff', size: 7 },
          { seriesIndex: 1, dataPointIndex: currentMonth, fillColor: '#e7515a', strokeColor: '#fff', size: 7 },
        ],
      },
      labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      xaxis: {
        axisBorder: { show: false },
        axisTicks: { show: false },
        crosshairs: { show: true },
        labels: { offsetX: 0, offsetY: 5, style: { fontSize: '12px', fontFamily: 'Nunito, sans-serif', cssClass: 'apexcharts-xaxis-title' } },
      },
      yaxis: {
        tickAmount: 7,
        labels: {
          // formatter: function (value) {
          //   return value / 1000 + 'K';
          // },
          offsetX: -10,
          offsetY: 0,
          style: { fontSize: '12px', fontFamily: 'Nunito, sans-serif', cssClass: 'apexcharts-yaxis-title' },
        },
      },
      grid: {
        borderColor: is_dark ? '#191e3a' : '#e0e6ed',
        strokeDashArray: 5,
        xaxis: { lines: { show: true } },
        yaxis: { lines: { show: false } },
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        offsetY: 0,
        fontSize: '16px',
        fontFamily: 'Nunito, sans-serif',
        markers: { width: 10, height: 10, strokeWidth: 0, strokeColor: '#fff', fillColors: undefined, radius: 12, onClick: undefined, offsetX: 0, offsetY: 0 },
        itemMargin: { horizontal: 20, vertical: 5 },
      },
      tooltip: { theme: 'dark', marker: { show: true }, x: { show: false } },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shadeIntensity: 1,
          inverseColors: !1,
          opacityFrom: is_dark ? 0.19 : 0.28,
          opacityTo: 0.05,
          stops: is_dark ? [100, 100] : [45, 100],
        },
      },
    };
  });

  // RadialBar
  const series_8 = ref([90, 9, 1]);

  const options_8 = computed(() => {
    return {
      chart: { toolbar: { show: false } },
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              fontSize: '18px',
              fontWeight: 'normal',
              label: 'Total',
              formatter: function () {
                return 24;
              },
            },
          },
        },
      },
      colors: ['#FEB019', '#00E396', '#FF4560'],
      labels: ['Pendientes', 'Entregadas', 'No Entregadas'],
    };
  });

  //Daily Sales
  // const daily_sales_series = ref([
  //     { name: 'Sales', data: [44, 55, 41, 67, 22, 43, 21] },
  //     { name: 'Last Week', data: [13, 23, 20, 8, 13, 27, 33] },
  // ]);
  // const daily_sales_options = computed(() => {
  //     return {
  //         chart: { toolbar: { show: false }, stacked: true, stackType: '100%' },
  //         dataLabels: { enabled: false },
  //         stroke: { show: true, width: 1 },
  //         colors: ['#e2a03f', '#e0e6ed'],
  //         responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }],
  //         xaxis: { labels: { show: false }, categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'] },
  //         yaxis: { show: false },
  //         fill: { opacity: 1 },
  //         plotOptions: { bar: { horizontal: false, columnWidth: '25%' } },
  //         legend: { show: false },
  //         grid: {
  //             show: false,
  //             xaxis: { lines: { show: false } },
  //             padding: { top: 10, right: -20, bottom: -20, left: -20 },
  //         },
  //     };
  // });

  //unique visitors - Entregas
  // const unique_visitor_series = ref([
  //     {
  //         name: 'Inicio',
  //         data: [
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //             '22-09-12 07:56:04',
  //         ],
  //     },
  //     {
  //         name: 'Inicio',
  //         data: [
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //             '22-09-12 15:36:04',
  //         ],
  //     },
  // ]);
  // const unique_visitor_options = computed(() => {
  //     const is_dark = store.state.is_dark_mode;
  //     return {
  //         chart: { toolbar: { show: false } },
  //         dataLabels: { enabled: false },
  //         stroke: { show: true, width: 2, colors: ['transparent'] },
  //         colors: ['#5c1ac3', '#ffbb44'],
  //         dropShadow: { enabled: true, opacity: 0.3, blur: 1, left: 1, top: 1, color: '#515365' },
  //         plotOptions: { bar: { horizontal: false, columnWidth: '55%', borderRadius: 10 } },
  //         legend: { position: 'bottom', horizontalAlign: 'center', fontSize: '14px', markers: { width: 12, height: 12 }, itemMargin: { horizontal: 0, vertical: 8 } },
  //         grid: { borderColor: is_dark ? '#191e3a' : '#e0e6ed' },
  //         xaxis: {
  //             categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
  //             axisBorder: { show: true, color: is_dark ? '#3b3f5c' : '#e0e6ed' },
  //         },
  //         yaxis: {
  //             type: 'datetime',
  //             tickAmount: 6,
  //             //agregado lables
  //             // labels: {
  //             //     formatter: function (value) {
  //             //         return value;
  //             //         // return value + ':00';
  //             //     },
  //             //     // offsetX: -10,
  //             //     // offsetY: 0,
  //             //     // style: { fontSize: '12px', fontFamily: 'Nunito, sans-serif', cssClass: 'apexcharts-yaxis-title' },
  //             // },
  //         },
  //         fill: {
  //             type: 'gradient',
  //             gradient: { shade: is_dark ? 'dark' : 'light', type: 'vertical', shadeIntensity: 0.3, inverseColors: false, opacityFrom: 1, opacityTo: 0.8, stops: [0, 100] },
  //         },
  //         tooltip: {
  //             theme: is_dark ? 'dark' : 'light',
  //             y: {
  //                 // formatter: function (val) {
  //                 //     return val;
  //                 // },
  //                 format: 'dd/MM/yy HH:mm',
  //             },
  //         },
  //     };
  // });

  //Total Orders
  // const total_orders_series = ref([{ name: 'Sales', data: [28, 40, 36, 52, 38, 60, 38, 52, 36, 40] }]);
  // const total_orders_options = computed(() => {
  //     const is_dark = store.state.is_dark_mode;
  //     return {
  //         chart: { sparkline: { enabled: true } },
  //         stroke: { curve: 'smooth', width: 2 },
  //         colors: is_dark ? ['#1abc9c'] : ['#fff'],
  //         labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  //         yaxis: { min: 0, show: false },
  //         grid: { padding: { top: 125, right: 0, bottom: 0, left: 0 } },
  //         fill: {
  //             opacity: 1,
  //             type: 'gradient',
  //             gradient: {
  //                 type: 'vertical',
  //                 shadeIntensity: 1,
  //                 inverseColors: !1,
  //                 opacityFrom: is_dark ? 0.3 : 0.4,
  //                 opacityTo: 0.05,
  //                 stops: is_dark ? [100, 100] : [45, 100],
  //             },
  //         },
  //         tooltip: { x: { show: false }, theme: 'dark' },
  //     };
  // });

  //Sales by Category - Progreso de Visitas
  // const sales_donut_series = ref([12, 23, 2]);
  // const sales_donut_series = ref(visitas.pro);
  // const sales_donut_options = computed(() => {
  //   const is_dark = store.state.is_dark_mode;
  //   const option = {
  //     chart: {},
  //     dataLabels: { enabled: false },
  //     expandOnClick: is_dark ? false : true,
  //     stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
  //     // colors: is_dark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
  //     colors: is_dark ? ['#e2a03f', '#1abc9c', '#e7515a'] : ['#e2a03f', '#1abc9c', '#e7515a'],
  //     legend: {
  //       position: 'bottom',
  //       horizontalAlign: 'center',
  //       fontSize: '14px',
  //       markers: { width: 10, height: 10 },
  //       height: 50,
  //       offsetY: 13,
  //       itemMargin: { horizontal: 8, vertical: 0 },
  //     },
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           size: '65%',
  //           background: 'transparent',
  //           labels: {
  //             show: true,
  //             name: { show: true, fontSize: '28px', fontFamily: 'Nunito, sans-serif', offsetY: -10 },
  //             value: {
  //               show: true,
  //               fontSize: '26px',
  //               fontFamily: 'Nunito, sans-serif',
  //               color: is_dark ? '#bfc9d4' : undefined,
  //               offsetY: 16,
  //               formatter: function (val) {
  //                 return val;
  //               },
  //             },
  //             total: {
  //               show: true,
  //               label: 'Total',
  //               color: '#888ea8',
  //               fontSize: '25px',
  //               formatter: function (w) {
  //                 return w.globals.seriesTotals.reduce(function (a, b) {
  //                   return a + b;
  //                 }, 0);
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     labels: ['Pendientes', 'Entregados', 'No Entregados'],
  //   };

  //   if (is_dark) {
  //     option['states'] = {
  //       hover: { filter: { type: 'none' } },
  //       active: { filter: { type: 'none' } },
  //     };
  //   }

  //   return option;
  // });

  const sales_donut_options1 = computed(() => {
    const is_dark = store.state.is_dark_mode;
    const option = {
      chart: {},
      dataLabels: { enabled: false },
      expandOnClick: is_dark ? false : true,
      stroke: { show: true, width: 25, colors: is_dark ? '#0e1726' : '#fff' },
      // colors: is_dark ? ['#5c1ac3', '#e2a03f', '#e7515a', '#e2a03f'] : ['#e2a03f', '#5c1ac3', '#e7515a'],
      // colors: is_dark ? ['#1abc9c', '#e2a03f', '#e7515a'] : ['#1abc9c', '#e2a03f', '#e7515a'],
      colors: is_dark ? ['#1abc9c', '#e7515a'] : ['#1abc9c', '#e7515a'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        markers: { width: 10, height: 10 },
        height: 50,
        offsetY: 13,
        itemMargin: { horizontal: 8, vertical: 0 },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: { show: true, fontSize: '28px', fontFamily: 'Nunito, sans-serif', offsetY: -10 },
              value: {
                show: true,
                fontSize: '26px',
                fontFamily: 'Nunito, sans-serif',
                color: is_dark ? '#bfc9d4' : undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: 'Total',
                color: '#888ea8',
                fontSize: '25px',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce(function (a, b) {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
      // labels: ['Activos', 'Inactivos', 'Bloqueados'],
      labels: ['Activos', 'Inactivos'],
    };

    if (is_dark) {
      option['states'] = {
        hover: { filter: { type: 'none' } },
        active: { filter: { type: 'none' } },
      };
    }

    return option;
  });

  //unique visitors
  const unique_visitor_series = ref([
    { name: 'Entregas', data: [58, 44, 55, 57, 56, 61, 58, 63, 60, 66, 56, 63, 58, 44, 55] },
    // { name: 'Visitas', data: [91, 76, 85, 101, 98, 87, 105, 91, 114, 94, 66, 70] },
  ]);
  const unique_visitor_options = computed(() => {
    const is_dark = store.state.is_dark_mode;
    return {
      chart: { toolbar: { show: false } },
      dataLabels: { enabled: false },
      stroke: { show: true, width: 2, colors: ['transparent'] },
      colors: ['#5c1ac3', '#ffbb44'],
      dropShadow: { enabled: true, opacity: 0.3, blur: 1, left: 1, top: 1, color: '#515365' },
      plotOptions: { bar: { horizontal: false, columnWidth: '35%', borderRadius: 10 } },
      legend: { position: 'bottom', horizontalAlign: 'center', fontSize: '14px', markers: { width: 12, height: 12 }, itemMargin: { horizontal: 0, vertical: 8 } },
      grid: { borderColor: is_dark ? '#191e3a' : '#e0e6ed' },
      xaxis: {
        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
        axisBorder: { show: true, color: is_dark ? '#3b3f5c' : '#e0e6ed' },
      },
      yaxis: {
        tickAmount: 6,
      },
      fill: {
        type: 'gradient',
        gradient: { shade: is_dark ? 'dark' : 'light', type: 'vertical', shadeIntensity: 0.3, inverseColors: false, opacityFrom: 1, opacityTo: 0.8, stops: [0, 100] },
      },
      tooltip: {
        theme: is_dark ? 'dark' : 'light',
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    };
  });
</script>
