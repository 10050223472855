const actions = {
    // /////////////////////////////////////////////
    // Las acciones son aquellas que realizan las mutaciones
    // /////////////////////////////////////////////
    login_access({ commit }, value) {
        // console.log('Valor que le llega al login_acces', value);
        commit('is_logged', value);
    },

    reload_page({ commit }, value) {
        // console.log('Valor que le llega al login_acces', value);
        commit('reload_page', value);
    },

    login_user({ commit }, data) {
        commit('user_logIn', data);
    },

    logOut_user({ commit }) {
        commit('user_logOut');
    },
};

export default actions;
